<template>
  <div class="jiaopi">
    <div class="xz">
      <div>
        <p>已向用户演示戴镜、摘镜</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q1" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q1" label="0">否</el-radio>
      </div>
      <div>
        <p>已让用户自行操作戴镜、摘镜</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q2" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q2" label="0">否</el-radio>
      </div>
      <div>
        <p>已向用户示范镜片护理</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q3" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q3" label="0">否</el-radio>
      </div>
      <div>
        <p>已让用户自行操作镜片护理</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q4" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q4" label="0">否</el-radio>
      </div>
      <div>
        <p>已告知用户镜片易碎</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q5" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q5" label="0">否</el-radio>
      </div>
      <div>
        <p>已告知用户镜盒吸棒护理方式</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q6" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q6" label="0">否</el-radio>
      </div>
      <div>
        <p>已将护理包交给用户</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q7" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q7" label="0">否</el-radio>
      </div>
      <div>
        <p>已将镜片浸泡</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q8" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q8" label="0">否</el-radio>
      </div>
      <div>
        <p>已告诉用户明晨戴镜复查</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q9" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q9" label="0">否</el-radio>
      </div>
      <div>
        <p>已告诉用户特别注意事项</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q10" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q10" label="0">否</el-radio>
      </div>
      <div>
        <p>已让用户签署《取片确认书》</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q11" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q11" label="0">否</el-radio>
      </div>
      <div>
        <p>已将热线号码告诉用户</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q12" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q12" label="0">否</el-radio>
      </div>
      <div>
        <p>已将签署知情同意书</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q13" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q13" label="0">否</el-radio>
      </div>
      <div>
        <p>已将说明书交给用户</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q14" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q14" label="0">否</el-radio>
      </div>
      <div>
        <p>已让用户购眼药水并告知用法</p>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q15" label="1">是</el-radio>
        <el-radio v-model="postData.S_OP_Json.CL_checkLens_Q15" label="0">否</el-radio>
      </div>
    </div>
    <div class="zysx">注意事项:1)用凉开水冲洗镜片，不要用自来水，2)先点润滑液，确认镜片在角膜上滑动后再摘镜，3)身体不适时暂停戴镜4)平时戴镜时感不适，可取下冲洗后重新戴上，如仍感不适，应停止戴镜，自点抗生素眼药水并及时前往验配处检查</div>
    <div class="zysx">温馨提示:镜片易碎，请细心呵护。</div>
    <div class="zysx">已掌握正确的洗、摘、戴镜操作方法及吸棒、双联盒的清洗方法。</div>
    <div class="fn">
      <p>收片日期</p>
      <el-date-picker
          v-model="postData.S_OP_Json.CL_checkLens_receiveDate"
          class="time"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
<!--      <el-button class="jpr">交片人签名</el-button>-->
<!--      <el-button>收片人签名</el-button>-->
    </div>
    <div class="db">
      <el-button class="fh">返回</el-button>
      <el-button @click="save">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "jiaopi",
  data() {
    return {
      postData: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: '',
        S_OP_ID: '',
        S_OP_Model: "CL_checkLens210629",
        S_OP_Time: '',
        S_OP_Type: "CL_checkLens",
        S_OP_XML: '',
        S_OP_Json: {
          CL_checkLens_Q1: '',
          CL_checkLens_Q2: '',
          CL_checkLens_Q3: '',
          CL_checkLens_Q4: '',
          CL_checkLens_Q5: '',
          CL_checkLens_Q6: '',
          CL_checkLens_Q7: '',
          CL_checkLens_Q8: '',
          CL_checkLens_Q9: '',
          CL_checkLens_Q10: '',
          CL_checkLens_Q11: '',
          CL_checkLens_Q12: '',
          CL_checkLens_Q13: '',
          CL_checkLens_Q14: '',
          CL_checkLens_Q15: '',
          CL_checkLens_receiveDate: '',
        }
      },
    }
  },
  created() {
    this._api.publicApi.PS_all_Normal_getList('CL_checkLens', '')
        .then(res => {
          if (res) {
             this.postData = JSON.parse(JSON.stringify(res[0]))
          }
        })
  },
  methods: {
    save() {
      this._api.publicApi.S_ALL_Normal_Save(this.postData)
    }
  }
}
</script>

<style scoped lang="scss">
.jiaopi {
  padding: 20px 30px;
  height: calc(87vh - 40px);
  overflow-y: auto;
}
.xz {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    padding-right: 5vw;
    padding-bottom: 15px;
    p {width: 16em;text-align: left;}
  }
}
.zysx {
  margin: 20px 0;
  text-align: left;
}
.fn {
  display: flex;
  align-items: center;
  justify-content: start;
  .time {
    margin: 0 20px;
  }
  .jpr {
    margin: 0 70px;
  }
}
.db {
  padding: 30px 0;
  .fh {margin-right: 50px;}
}
</style>
